<template>
  <footer>
    <div class="container">
      <ul class="footer1">
        <li>专注服务于电商领域</li>
        <li>7 * 24 小时售后支持</li>
        <li>一对一专属服务</li>
        <li>值得首选</li>
      </ul>

      <ul class="fonter2">
        <li class="fw">
          <h4>服务热线</h4>
          <p class="number">020-86574220</p>
          <span>全方位购买咨询</span>
          <span>精准的产品推荐</span>
          <span>专业支持的售后咨询</span>
          <span>电商业务开展咨询</span>
        </li>
        <li class="line"></li>
        <li class="code">
          <h4>客服微信</h4>
          <img src="@/assets/images/wx_code.png"
               alt="">
          <!-- <img src="@/assets/images/wx_code.png"
               alt=""> -->
        </li>
      </ul>
    </div>

    <div class="beian">
      <a href="https://beian.miit.gov.cn/"
         target="_blank"
         rel="external nofollow">粤ICP备17163402号-5</a>
      <a style="margin-left: 5px;"
         href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=联网备案号xxx"
         target="_blank"
         rel="external nofollow">
        <img src="https://s.stcfile.com/static/img/beian.png"
             alt="">
        <span style="margin-left: 5px;">极汇互动(广东)信息科技有限公司</span>
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}

</script>

<style lang="scss" scoped>
footer {
  padding: 30px;
  background-color: #000;
  color: #fff;
}

.footer1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  li {
    width: 20%;
    padding: 10px;
    border: 1px solid #364c63;
    color: #364c63;
  }
}

.fonter2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  li {
    width: 33.333%;
    color: #364c63;

    &.line {
      width: 1px;
      margin: 0 80px;
      background-color: #364c63;
    }

    &.bz {
      a {
        display: inline-block;
        width: 60px;
        cursor: pointer;

        & + a {
          margin-left: 10px;
        }
      }
    }

    &.fw {
      .number {
        font-size: 20px;
        color: #fff;
        margin-bottom: 10px;
      }

      span {
        display: inline-block;
        width: 50%;
        padding: 10px 0;
        box-sizing: border-box;
      }
    }

    &.code {
      img {
        width: 30%;
        & + img {
          margin-left: 20px;
        }
      }
    }

    h4 {
      color: #fff;
      margin-bottom: 10px;
    }
  }
}

.beian {
  text-align: center;
  a {
    text-decoration: none;
    color: #364c63;
  }
}
</style>
