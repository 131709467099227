.<template>
  <div>
    <Header />
    <section>
      <div class="container container-space-y-m">
        <div class="d-flex">
          <img src="@/assets/images/voice-code/phone.png"
               alt="">
          <div class="ml-5">
            <div class="h4">语音验证码</div>
            <p>企业在用户注册、登录认证、交易支付、密码找回等场 景下使用的高效、便捷的验证方式。有效的解决了无法 收发短信、拦截、刷单等痛点。</p>
          </div>
        </div>

      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'VoiceCode',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      point: {},
      showInfoWindow: true // 显示信息窗口
    }
  },

  methods: {
    mapReady ({ BMap, map }) {
      // 选择一个经纬度作为中心点
      this.point = new BMap.Point(113.44308, 23.194689)
      map.centerAndZoom(this.point, 17)
    }

  }
}
</script>

<style lang="scss" scoped>
/deep/.device-browser {
  width: 800px;
}
</style>
