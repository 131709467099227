<template>
  <header>
    <div class="container d-flex">
      <div class="logo"></div>
      <div class="flex-grow-1"></div>
      <ul class="nav">
        <router-link tag="li"
                     to="/home">首页</router-link>
        <router-link tag="li"
                     to="/sms-code">短信验证码</router-link>
        <router-link tag="li"
                     to="/voice-code">语音验证码</router-link>
        <!-- <router-link tag="li">电商系统及行业方案</router-link>
        <router-link tag="li">客户案例</router-link> -->
        <router-link tag="li"
                     to="/contact-us">联系我们</router-link>
      </ul>
      <div></div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header'
}

</script>

<style lang="scss" scoped>
header {
  display: flex;
  width: 100%;
  height: 70px;
  background: #000;
  position: sticky;
  top: 0;
  z-index: 999;
}

.logo {
  width: 200px;
  background: url("~@/assets/images/logos/ym_white.png") center no-repeat;
  background-size: 40%;
}

.nav {
  li {
    float: left;
    height: 70px;
    line-height: 70px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;

    & + li {
      margin-left: 20px;
    }
  }
}
</style>
